import React, { useState, useEffect } from 'react';
import intersection from 'lodash/intersection';

const TextInput = (props) => {
  const {
    errors,
    label,
    icon,
    isRequired,
    disabled,
    placeholder,
    customClass,
    statesList,
    handleStateChange,
    showStateSuggestion,
    ...attrs
  } = props;
  const haveIcon = icon ? 'input with-icon' : 'input';
  const addMouseflowHiddenClass = attrs.name !== 'state' ? 'no-mouseflow' : '';
  const [suggestedItems, setSuggestedItems] = useState(statesList);
  const [showDropdown, setShowDropdown] = useState(false);
  const [textValue, setTextValue] = useState('');

  const suggestStates = (text) => {
    const stateCodeMatch = statesList?.filter((state) => state.code.toLowerCase().includes(text.toLowerCase()));
    const stateNameMatch = statesList?.filter((state) => state.name.toLowerCase().includes(text.toLowerCase()));
    let finalMatch;

    if (stateCodeMatch?.length && !stateNameMatch?.length) {
      finalMatch = stateCodeMatch;
    } else if (stateNameMatch?.length && !stateCodeMatch?.length) {
      finalMatch = stateNameMatch;
    } else {
      finalMatch = intersection(stateCodeMatch, stateNameMatch);
    }

    setSuggestedItems(finalMatch);
  }

  let input = (
    <div className={`text-field-wrapper position-relative ${customClass}`}>
      { !!label
      && (
      <label className="input-label" htmlFor={attrs.name}>
        {label}
      </label>
      )}
      <div className={`input-group row mx-0 flex-row ${errors && errors[0] ? 'field_with_errors' : ''}`}>
        <input
          required={isRequired}
          disabled={disabled}
          className={`${addMouseflowHiddenClass} ${haveIcon}`}
          placeholder={placeholder}
          onFocus={() => setShowDropdown(true)}
          onBlur={() => setShowDropdown(false)}
          autoComplete="off"
          onKeyUp={(e) => {
            setTextValue(e.target.value);
            suggestStates(e.target.value);
          }}
          value={textValue}
          {...attrs}
        />
        { icon ? <span className={`input__icon ${icon}`} /> : null }
      </div>

      {
        (showStateSuggestion && !!suggestedItems?.length && showDropdown) &&
        (
          <ul className="states-list-dropdown">
            {suggestedItems?.map((state, i) => {
              return(
                <li key={i}>
                  <a
                    href="#"
                    onMouseDown={() => {
                      handleStateChange(state.code);
                      setShowDropdown(false);
                      setSuggestedItems(statesList);
                    }}
                    onClick={e => e.preventDefault()}
                  >{state.name}</a>
                </li>
              )
            })}
          </ul>
        )
      }
      {errors && errors[0] && (
        <span className="input__error">{errors[0]}</span>
      )}
    </div>
  );

  if (attrs.type === 'hidden') {
    input = (<input {...attrs} />);
  }

  return input;
};

export default TextInput;
